import React from "react"
import Layout from "../components/layout"

export const seo = {
  title: "Refund Policy | Crampete",
  description:
    "Learn Full Stack Web Development, Data Science, Digital Marketing, and other in-demand tech skills via online, classroom and self-paced courses. It's time to up-skill!",
  keywords:
    "Full Stack developer online course, front end, back end, data science, digital marketing",
  image: "",
  url: "https://www.crampete.com",
  author: "Crampete",
}

const privacyPolicy = () => {
    return (
        <Layout title={seo.title}
        description={seo.description}
        image={seo.image}
        keywords={seo.keywords}
        url={seo.url}
        author={seo.author} >
            <section>
                <div className="container">
                <h3>Refund/Cancellation Policy</h3>
                    <p>No Refunds. As we are a training partner and the Fee paid by the students are for training purpose only. The Fee once paid will not be refunded under any circumstances However, the fee may be refunded only if our company fails to start a course (Not applicable for unavoidable instances). Delay in starting of the class by fortnight shall not be considered as not starting.</p>
                    <p>Crampete Learning Private Limited reserves the right to reschedule/cancel the batch because of insufficient enrollments, instructor unavailability, or force majeure events (like floods, earthquakes, etc). In case Crampete cancels a batch, a 100% refund will be paid to the students.</p>
                    <p>Refunds of the duplicate payment made by the students will be processed via the same source (original method of payment) in 7 to 10 working days post intimation by the student and approval of Crampete Learning Private Limited.</p>
                    <p>This policy is effective as of 5th May 2021.</p>
                </div>
            </section>
        </Layout>
    )
}

export default privacyPolicy